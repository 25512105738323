import * as React from "react"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import { container, intro } from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }
  }
`

export default function ShopPage(props) {
  const { data, path } = props

  return (
    <Layout path={path}>
      <ProductListing products={data?.shopifyCollection?.products} />
    </Layout>
  )
}
